import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'

function Post({post, onLike}) {
  return (
    <Card style={{ margin: '20px' }}>
      <Card.Body>
        <Card.Title>{post.title}</Card.Title>
        <Card.Text>{post.content}</Card.Text>
        <Card.Text style={{color: 'blue'}}>{post.username}</Card.Text>
        {(post.img !== undefined && post.img !== "") &&
          <div style={{height: '200px', width: '200px'}}>
            <img src={post.img} style={{maxWidth: '100%', maxHeight: '100%'}} />
          </div>
        }
        <Col>
          <Button variant="tranparent" onClick={onLike}><FontAwesomeIcon icon={faThumbsUp} /></Button>
          <Button variant="transparent" disabled>{post.likes}</Button>
        </Col>
      </Card.Body>
    </Card>
  )
}

const Posts = ({posts, onLike}) => {
  return (
    <>
      {posts.map((post) => (
            <Post key={post.id} post={post} onLike={() => onLike(post.id)}/>
      ))}
    </>
  );
};

export default Posts;