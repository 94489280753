import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from "react";
import { faCamera, faVideo, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Posts from './components/posts'

function App() {

  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [imgsrc, setImgsrc] = useState("")
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts()
  }, [])

  const getPosts = async () => {
    const resp = await fetch(
      "https://workers.collinzrj.workers.dev/posts"
    );
    const posts = await resp.json();
    posts.sort((a, b) => {
      return b.likes - a.likes
    })
    console.log(posts)
    setPosts(posts);
  };

  async function sendPost(e) {
    document.activeElement.blur()
    if (text === "" || title === "" || userName === "") {
      return
    }
    // upload photo if selected
    if (imgsrc!=="") {
      let blob = await fetch(imgsrc).then(r => r.blob());
      var data = new FormData()
      data.append('file', blob)
      // the worker will request cloudflare imags upload api
      fetch('https://workers.collinzrj.workers.dev/uploadURL')
        .then((res) => {
            return res.json()
          })
        .then((json) => {
          // parse response for the URL to upload photo
          const url = json["result"]["uploadURL"]
          fetch(url, {
            method: 'POST',
            body: data
          })
          .then((res) => {
            return res.json()
          })
          .then((json) => {
            // parse response to get the url of photo serverd on cloudflare
            const url = json["result"]["variants"][0]
            sendPostText(url)
          })
          .catch((err)=> {
            console.log(err)
          })
        })
    } else {
      sendPostText("")
    }
  }

  function sendPostText(url) {
    fetch("https://workers.collinzrj.workers.dev/posts", {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify({'title': title, 'username': userName, 'content': text, 'img': url})
    })
    .then((res) => {
      return res.text()
    })
    .then((text)=> {
      if (text==="success") {
        // clear input when success
        getPosts()
        setText("")
        setTitle("")
        setUserName("")
        setImgsrc("")
      }
    })
  }

  function uploadPhoto(e) {
    document.getElementById('fileid').click();
  }

  function photoUploaded(e) {
    try {
      setImgsrc(URL.createObjectURL(e.target.files[0]))
    }
    catch(e) {
      console.log(e)
    }
  }

  function handleLike(id) {
    document.activeElement.blur()
    // notify the server user has liked a post
    fetch('https://workers.collinzrj.workers.dev/likePost/' + id, {
      method: 'POST'
    })
    .then((res) => {
      return res.text()
    })
    .then((text) => {
      if (text === "success") {
        // upload likes locally to save bandwidth
        const newPosts = posts.map((post) => {
          if (post.id === id) {
            const newPost = {
              ...post,
              likes: post.likes + 1
            }
            return newPost
          }
          return post
        })
        setPosts(newPosts)
      }
    }) 
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <div style={{padding: '20px'}}>
      <h1>Welcome to my Blog!</h1>
      <Form.Control 
        type="title" 
        placeholder="Please enter the title" 
        value={title}
        onChange={(e)=> setTitle(e.target.value)}
      />
      <Form.Control 
        style={{marginTop: '10px'}} 
        type="username" 
        placeholder="Please enter your username"
        value={userName}
        onChange={(e)=> setUserName(e.target.value)} 
      />
      <Form.Control
        as="textarea"
        placeholder="What's in your mind?"
        style={{ height: '150px', marginTop: '10px' }}
        value={text} 
        onChange={(e)=> setText(e.target.value)}
      />
      <Row style={{'margin-top': '10px'}}>
        <Col>
          <input id='fileid' type='file' onChange={photoUploaded} hidden/>
          <Button variant="tranparent" onClick={uploadPhoto}><FontAwesomeIcon icon={faCamera} /></Button>
        </Col>
        <Col align="right">
          <Button as="input" type="submit" value="Submit" onClick={sendPost} />{' '}
        </Col>
      </Row>
      {
        imgsrc!=="" && 
      <div style={{height: '200px', width: '200px'}}>
        <img id='post_img' src={imgsrc} style={{maxWidth: '100%', maxHeight: '100%'}} />
      </div>
      }
      </div>
      <Posts posts={posts} onLike={handleLike}/>
    </>
  );
}

export default App;

